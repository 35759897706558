<template>
  <div class="NoAccount">
    <div class="NoAccountTit">账户初始化</div>
    <p class="NoAccountTitBot">在开始企业账户前，请先进行签章认证，并完成以下账户初始化设置：</p>
    <el-form
      :model="ruleForm"
      :rules="rules"
      ref="ruleForm"
      label-width="180px"
      class="demo-ruleForm"
    >
      <el-form-item label="企业全称" prop="name">
        <span>{{ ruleForm.name }}</span>
      </el-form-item>
      <el-form-item label="统一社会信用代码" prop="manufactoryCode">
        <span>{{ ruleForm.manufactoryCode }}</span>
      </el-form-item>
      <el-form-item label="对公账户开户银行" prop="corporateAccountBank">
        <el-input
          v-model="ruleForm.corporateAccountBank"
          autocomplete="off"
          placeholder="请输入对公账户开户银行" 
        ></el-input>
      </el-form-item>
      <el-form-item
        label="对公账户开户行行号"
        prop="corporateAccountBranchNumber"
      >
        <el-input
          v-model="ruleForm.corporateAccountBranchNumber"
          autocomplete="off"
          placeholder="请输入对公账户开户行行号" 
        ></el-input>
      </el-form-item>
      <el-form-item label="对公账户账号（卡号）" prop="corporateAccountNumber">
        <el-input
          v-model="ruleForm.corporateAccountNumber"
          placeholder="请输入对公账户账号（卡号）" 
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="支付密码" prop="paymentPassword">
        <el-input
          v-model="ruleForm.paymentPassword"
          type="password"
          show-password
          placeholder="请输入6位支付密码" 
        ></el-input>
      </el-form-item>
      <el-form-item label="确认支付密码" prop="paymentPassword2">
        <el-input
          v-model="ruleForm.paymentPassword2"
          type="password"
          placeholder="请再次输入6位支付密码" 
          show-password
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')"
          >开启企业账户</el-button
        >
      </el-form-item>
    </el-form>
  </div>
</template>
  
  <script>
import { encrypt, decrypt } from "@/utils/jsencrypt.js";
import { insertWalletInfo } from "@/api/account";
import { queryManuFactoryInfo } from "@/api/personnelManagement";
import { mapState } from "vuex";
export default {
  data() {
    let validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error("请再次输入密码"));
      } else if (value !== this.ruleForm.paymentPassword) {
        callback(new Error("两次输入密码不一致!"));
      } else {
        callback();
      }
    };

    return {
      ruleForm: {
        name: "",
      },
      rules: {
        factoryType: [
          { required: true, message: "请输入企业类型", trigger: "blur" },
        ],
        paymentPassword: [
          { required: true, message: "请输入6位支付密码", trigger: "change" },
          {
            pattern: /^\d{6}$/,
            message: "请输入6位数字密码",
            trigger: "change",
          },
        ],
        paymentPassword2: [{ validator: validatePass2, trigger: "blur" }],
      },
    };
  },
  mounted() {
    this.getData();
  },
  methods: {
    getData() {
      queryManuFactoryInfo().then(({ code, data }) => {
        if (code == 200) {
          this.ruleForm.name = data.name;
          this.ruleForm.manufactoryCode = data.manufactoryCode;
        }
      });
    },
    submitForm(formName) {
     if(this.userInfo.signatureStatus!=0){
      return  this.$message.error("开通账户前，请先进行签章认证");
     } 
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let {
            corporateAccountBank,
            corporateAccountBranchNumber,
            corporateAccountNumber,
            paymentPassword,
            paymentPassword2,
          } = this.ruleForm;
          let param = {
            corporateAccountBank,
            corporateAccountBranchNumber,
            corporateAccountNumber,
            paymentPassword: encrypt(paymentPassword + ""),
            paymentPassword2: encrypt(paymentPassword2 + ""),
          };

          insertWalletInfo(param).then(async ({ code, msg }) => {
            if (code == 200) {
              try {
                //代表用户登录了，但是没有用户信息，发请求让仓库存储用户信息，在进行路由跳转
                this.$message.success(msg);
                await this.$store.dispatch("getUserInfo");
                this.$router.push({ path: "/Account" });
              } catch (error) {
                this.$message.error(msg);
              }
            } else {
              this.$message.error(msg);
            }
          });
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
    //  this.areaList = this.$store.state.area.areaList;
  },
  computed: {
    ...mapState({
      userInfo: (state) => state.user.userInfo,
    }),
  },
};
</script>
  
  <style lang="less" scoped>
.NoAccount {
  margin-top: 20px;
  margin-left: 30px;
  width: 600px;
  .NoAccountTit {
    font-weight: 600;
    font-size: 18px;
    text-align: center;
    line-height: 40px;
  }
  .NoAccountTitBot {
    line-height: 40px;
    border-bottom: 1px solid #ccc;
    color: rgb(214, 4, 4);
  }
}
</style>
  